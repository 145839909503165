import React from "react";
import { Link } from "gatsby";
import Row from "react-bootstrap/Row";

export default () => (
  <Row
    className="laas-dark-grey justify-content-around flex-column flex-sm-row"
    style={{ paddingTop: "70px", paddingBottom: "80px" }}
  >
    {[
      ["DONATE TO LAAS", "/donate"],
      ["PRESS ENQUIRIES", "/press"],
      ["REPORT ANTISEMITISM", "/report-antisemitism"]
    ].map((a, i) => (
      <Link to={a[1]} className="font-cb mx-auto" key={a[i]}>
        <i className="fas fa-caret-right laas-text-br mr-2"></i>
        <span className="text-dark">{a[0]}</span>
      </Link>
    ))}
  </Row>
);
