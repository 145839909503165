import React, { useState } from "react";

import Layout from "../components/Layout";
import ThreeLinkBlock from "../components/ThreeLinkBlock";

import Form from "react-bootstrap/Form";

export default () => {
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  // const [filename, setFilename] = useState(null);
  // const [attachment, setAttachment] = useState(null);

  const handleSuccess = () => setSuccess(true);
  const handleFailure = () => setFailure(true);

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.currentTarget;
    setValidated(true);

    if (form.checkValidity()) {
      const name = form.elements.name.value;
      const email = form.elements.email.value;
      const subject = form.elements.subject.value;
      const enquiry = form.elements.enquiry.value;

      const qs = `name=${name}&email=${email}&subject=${subject}&enquiry=${enquiry}`;
      fetch(`/.netlify/functions/report?${qs}`, {
        method: "POST",
        body: null
      })
        .then(handleSuccess)
        .catch(handleFailure);
    }
  };

  // const handleFileInputchange = e => {
  //   const file = e.currentTarget.files[0];
  //   setFilename(file.name);
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     setAttachment(reader.result);
  //   };
  // };

  return (
    <Layout>
      <div className="row pt-5">
        <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
          <h1 className="font-cb">Report Antisemitism</h1>
          <p className="pt-2">
            If you would like to let us know about any Labour antisemitism, then
            please use this form. If you have provided us with your details,
            they will always be kept strictly confidential. Please note, we are
            not in any way a replacement for the{" "}
            <a
              href="https://cst.org.uk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CST
            </a>{" "}
            or the Police. If you believe a crime has been committed or are
            concerned for the safety of yourself or others, then please contact
            them directly.
          </p>
          {success && (
            <div>
              <h2>Success!</h2>
              <h3>Thanks for getting in touch, someone will respond asap</h3>
            </div>
          )}
          {failure && (
            <div>
              <h2>Oops!</h2>
              <h3>
                There was a problem with submitting the form. Please email{" "}
                <a
                  href="mailto:labouragainstas@outlook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  labouragainstas@outlook.com
                </a>
              </h3>
            </div>
          )}
          {!success && !failure && (
            <Form
              className="needs-validation py-4"
              validated={validated}
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="form-row">
                <div className="col-6">
                  <label className="mb-0 pt-3" htmlFor="nameInput">
                    Name (optional)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nameInput"
                    placeholder="Name"
                    required
                    name="name"
                  />
                </div>
                <div className="col-6">
                  <label className="mb-0 pt-3" htmlFor="emailInput">
                    Email*
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailInput"
                    placeholder="Email address"
                    required
                    name="email"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="mb-0 pt-3" htmlFor="subjectInput">
                  Subject*
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Subject"
                  required
                  name="subject"
                />
              </div>
              <div className="form-group">
                <label className="mb-0" htmlFor="enquiryInput">
                  Enquiry*
                </label>
                <textarea
                  className="form-control"
                  id="enquiryInput"
                  rows="3"
                  required
                  name="enquiry"
                ></textarea>
              </div>
              {/* <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="attachmentInput"
                    aria-describedby="attachmentInput"
                    name="attachments"
                    multiple
                    onChange={handleFileInputchange}
                  />
                  <label
                    className="custom-file-label text-truncate"
                    htmlFor="attachmentInput"
                  >
                    Upload attachments (e.g. screenshots)
                  </label>
                </div>
              </div> */}
              {/* <div className="mb-3">{filename}</div> */}
              <button
                type="submit"
                className="btn btn-block btn-primary laas-bg-dr border-0"
              >
                Send your report
              </button>
            </Form>
          )}
        </div>
      </div>
      <ThreeLinkBlock />
    </Layout>
  );
};
